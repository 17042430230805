import JSON5 from 'json5'

export default {
  parse: function (input: string | string[], reviver?: (this: any, key: string, value: any) => any) {
    try {
      if (Array.isArray(input)) {
        return input.reduce((arr, string) => {
          arr.push(JSON5.parse(string))
          return arr
        }, [])
      }
      return JSON5.parse(input)
    } catch (error) {
      return undefined
    }
  },
  stringify: JSON5.stringify,
}