import axiosClient from './axiosClient'
import flatten from 'flat'
import get from 'lodash/get'
import set from 'lodash/set'
import { ValueKey } from 'swr/dist/types'
import json5 from './json5'

export const fetcher = (url: string) => axiosClient.get(url).then(res => res.data)

export const buildURL = <Q = any>(urlString: string, query?: Q, qBased = true) => {
  try {
    const isRelativeUrl = urlString.charAt(0) === '/'
    if (isRelativeUrl && typeof location === 'undefined') {
      return null
    }
    const url = new URL(urlString, isRelativeUrl ? String(location) : undefined)
    if (isRelativeUrl) {
      url.pathname = urlString
    }
    if (query) {
      if (qBased) {
        url.searchParams.set('q', json5.stringify(query))
      } else {
        Object.entries(flatten(query)).forEach(([name, value]) => value !== undefined && url.searchParams.append(name, String(value)))
      }
    }
    return String(url)
  } catch (error) {
    console.warn('buildUrl', { error })
    return null
  }
}

export const buildKey = <Q = any>(url: string, query?: Q, qBased?: boolean) => function (): ValueKey {
  const pageIndex: number = arguments[0]
  const previousPageData: any = arguments[1]
  if (previousPageData && !previousPageData.length) {
    return null
  }
  const take = get(query, 'take', 100)
  set(query as any, 'skip', pageIndex * take)
  return buildURL(url, query as Q, qBased)
}