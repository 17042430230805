// import { ExclamationSolid, XSolid } from '@graywolfai/react-heroicons'
import { ExclamationIcon, XIcon } from '@heroicons/react/solid'
import { createContext, FC, ReactNode, useContext, useState } from 'react'
import Modal, { ModalBody, ModalContent, ModalPanel, ModalTitle } from '../components/Modal'

export type Alert = ({ title, content }: {
  title: ReactNode | ((dismiss: () => void) => ReactNode)
  content: ReactNode | ((dismiss: () => void) => ReactNode)
  icon?: ReactNode | ((dismiss: () => void) => ReactNode)
}) => void

const AlertContext = createContext<Alert>(undefined)

export const AlertProvider: FC = ({ children }) => {
  const [state, setState] = useState<{
    show: boolean
    title: ReactNode
    content: ReactNode
    icon: ReactNode
  }>({
    show: false,
    title: null,
    content: null,
    icon: null,
  })

  const alert: Alert = ({ title, content, icon }) => {
    setState({
      show: true,
      title,
      content,
      icon: icon ?? (
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          {/* <ExclamationSolid className="h-6 w-6 text-red-600" /> */}
          <ExclamationIcon className="h-6 w-6 text-red-600" />
        </div>
      ),
    })
  }

  const dismiss = () => {
    setState(prev => ({ ...prev, show: false }))
  }

  return (
    <AlertContext.Provider value={alert}>
      <Modal show={state.show} onDismiss={dismiss} className="z-[2000]">
        <ModalPanel>
          <ModalTitle className="flex flex-col-reverse sm:flex-row sm:justify-between">
            {state.title ? (
              typeof state.title === 'function' ? state.title(dismiss) : state.title
            ) : null}
          </ModalTitle>
          <ModalContent className="relative min-w-xs sm:w-auto max-w-full">
            {state.content ? (
              <ModalBody>
                {typeof state.content === 'function' ? state.content(dismiss) : state.content}
              </ModalBody>
            ) : null}
          </ModalContent>
        </ModalPanel>
      </Modal>
      {children}
    </AlertContext.Provider>
  )
}

const useAlert = () => useContext(AlertContext)

export default useAlert
