// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBKebZz-kVEWAFEfnP5lIMUZmS2kK5JsPI',
  authDomain: 'log-trading.firebaseapp.com',
  projectId: 'log-trading',
  storageBucket: 'log-trading.appspot.com',
  messagingSenderId: '831726829143',
  appId: '1:831726829143:web:aefe0ba3f7ab563a30ad12',
  measurementId: 'G-84Y6D755HG',
}

export default firebaseConfig