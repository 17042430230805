import { createPortal } from 'react-dom'
import { createContext, FC, useContext, useEffect, useRef, useState } from 'react'

const PortalContext = createContext<HTMLDivElement>(null)

export const PortalProvider: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState<HTMLDivElement>()
  useEffect(() => {
    setValue(ref.current)
  }, [])
  return (
    <PortalContext.Provider value={value}>
      {children}
      <div ref={ref} />
    </PortalContext.Provider>
  )
}

interface Props {
  element?: Element
}

export const Portal: FC<Props> = ({ element, children }) => {
  const defaultElement = useContext(PortalContext)
  const node = element ?? defaultElement
  return node ? createPortal(children, node) : null
}