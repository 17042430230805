import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { createContext, ElementType, FC, HTMLAttributes, useContext } from 'react'

type ModalTitleProps = {
  className?: string
  dismissable?: boolean
  as?: keyof JSX.IntrinsicElements
}

export const ModalTitle: FC<ModalTitleProps> = ({ className = '', children, as: Tag = 'h2', dismissable = true }) => {
  const { onDismiss } = useModalContext()

  return (
    <Tag className={`sticky flex justify-between font-bold border-b text-xl top-0 z-10 px-4 pt-4 pb-4 sm:p-6 sm:pb-4 bg-white ${className}`.replace(/\s\s+/g, ' ').trim()}>
      {children}
      {dismissable ? (
        <button type="button"
          className="p-1 -m-1 rounded-full focus:outline-none focus:bg-black/10 hover:bg-black/10"
          onClick={() => {
            onDismiss?.()
          }}
        >
          <XIcon className="w-6 h-6" />
        </button>
      ) : null}
    </Tag>
  )
}

type ModalPanelProps = HTMLAttributes<HTMLElement> & {
  as?: ElementType<any>
}

export const ModalPanel: FC<ModalPanelProps> = ({
  className = '',
  children,
  ...props
}) => {
  return (
    <>
      {/* <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}
      <Transition.Child
        className={`
          ${className}
          inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all relative
          sm:mt-8 sm:mb-20 sm:align-middle sm:max-w-lg sm:w-full
        `.replace(/\s\s+/g, ' ').trim()}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        {...props}
      >
        {children}
      </Transition.Child>
    </>
  )
}

interface ModalContentProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
}

export const ModalContent: FC<ModalContentProps> = ({ className = '', children, as: Tag = 'div' }) => (
  <Tag className={`bg-white px-4 pt-4 pb-4 sm:p-6 sm:pb-4 ${className}`.replace(/\s\s+/g, ' ').trim()}>
    {children}
  </Tag>
)

interface ModalBodyProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
}

export const ModalBody: FC<ModalBodyProps> = ({ className = '', children, as: Tag = 'div' }) => (
  <Tag className={`text-sm text-gray-500 space-y-2 ${className}`.replace(/\s\s+/g, ' ').trim()}>
    {children}
  </Tag>
)

interface ModalFooterProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
}

export const ModalFooter: FC<ModalFooterProps> = ({ className = '', children, as: Tag = 'div' }) => (
  <Tag className={`bg-white sticky bottom-0 border-t px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ${className}`.replace(/\s\s+/g, ' ').trim()}>
    {children}
  </Tag>
)

interface Props {
  show: boolean
  className?: string
  backdropDismissable?: boolean
  onDismiss?: () => void
}

type TModalContext = {
  onDismiss: () => void
}

const ModalContext = createContext<TModalContext>({
  onDismiss: () => { }
})

export const useModalContext = () => useContext(ModalContext)

const Modal: FC<Props> = ({ show, className = '', onDismiss = () => { }, children, backdropDismissable = false }) => {
  return (
    <ModalContext.Provider value={{ onDismiss }}>
      <Transition show={show} className={`fixed z-10 inset-0 ${className}`.replace(/\s\s+/g, ' ').trim()}>
        <Transition.Child className="fixed inset-0 transition-opacity"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-900 opacity-25" onClick={() => backdropDismissable && onDismiss?.()} />
        </Transition.Child>
        {/* <div className="relative overflow-y-auto h-screen pt-4 px-4 pb-20 text-center sm:p-0"> */}
        <div className="relative overflow-y-auto h-screen text-center">
          {/* <!--
            Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          --> */}

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          {children}
        </div>
      </Transition>
    </ModalContext.Provider>
  )
}

export default Modal
