import { AxiosRequestConfig } from 'axios'

const config: AxiosRequestConfig = {
  // baseURL: '/api/',
  // headers: {
  //   Accept: 'application/json',
  // },
  // responseType: 'json',
  // timeout: 30 * 1000,
}

export default config