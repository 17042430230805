import { Account, AccountPermission, User } from '@prisma/client'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import useSWR from 'swr'
import { fetcher } from '../libs/swrHelpers'
import { getAuth, User as FirebaseAuthUser, onIdTokenChanged, onAuthStateChanged } from 'firebase/auth'

type AccountType = Account & {
  permissions: AccountPermission[]
}

interface ResponseData {
  // token: string
  // decodedToken: firebaseAdmin.auth.DecodedIdToken
  // userRecord: firebaseAdmin.auth.UserRecord
  user: User
  accounts: AccountType[]
  primaryAccount: AccountType
  account: AccountType
}

interface Auth {
  currentUser: FirebaseAuthUser
  loading: boolean
  isReady: boolean
  setAccountId: (accountId: number) => void
  user: User
  account: AccountType
  accounts: AccountType[]
  primaryAccount: AccountType
  signOut: () => Promise<void>
  // claims: {
  //   [key: string]: any
  // }
  mutate: (data?: ResponseData | Promise<ResponseData>, shouldRevalidate?: boolean) => Promise<ResponseData>
}

const AuthContext = createContext<Auth>({
  currentUser: undefined,
  loading: true,
  isReady: false,
  setAccountId: undefined,
  user: undefined,
  account: undefined,
  accounts: undefined,
  primaryAccount: undefined,
  signOut: undefined,
  mutate: undefined,
})

export const AuthProvider: FC = ({ children }) => {
  const initialCurrentUser = getAuth().currentUser
  const [accountId, setAccountId, removeAccountId] = useLocalStorage('accountId', undefined)
  const [state, setState] = useState({
    firebaseReady: !!initialCurrentUser,
    currentUser: initialCurrentUser,
    // accountId: undefined,
    // claims: undefined,
  })
  const { data, isValidating, mutate } = useSWR<ResponseData>(state.currentUser?.uid ? '/api/auth' : null, fetcher)
  const { user, primaryAccount, accounts } = data || {}

  const loading = !state.firebaseReady || isValidating
  const isReady = state.firebaseReady && (!state.currentUser?.uid || (!!state.currentUser?.uid && data !== undefined))

  const signOut = async () => {
    // removeAccountId()
    await getAuth().signOut()
    removeAccountId()
  }
  // const setAccountId = (accountId: string) => setState(prev => ({ ...prev, accountId }))
  const handleIdTokenChanged = async (currentUser: FirebaseAuthUser) => {
    // console.log('handleIdTokenChanged', {
    //   currentUser,
    //   asd: getAuth().currentUser,
    // })
    // const onAuthStateChanged = async (currentUser: firebaseApp.User) => {
    if (!currentUser) {
      mutate(data, false)
    }
    // const idTokenResult = await currentUser?.getIdTokenResult?.()
    setState(prev => ({
      ...prev,
      firebaseReady: true,
      currentUser,
      // claims: idTokenResult?.claims,
    }))
  }

  // const handleAuthStateChanged = async (currentUser: FirebaseAuthUser) => {
  //   console.log('handleAuthStateChanged', currentUser)

  //   if (!currentUser) {
  //     mutate(data, false)
  //   }
  //   setState(prev => ({
  //     ...prev,
  //     firebaseReady: true,
  //     currentUser,
  //   }))
  // }

  // const accountIds = accounts?.map(account => account.id)

  // useEffect(() => {
  //   if (!accountIds?.find(id => id === accountId)) {
  //     setAccountId(accounts?.find(a => a.permissions[0].isDefault)?.id || primaryAccount?.id)
  //   }
  // }, [JSON.stringify(accountIds)])

  const account = accounts?.find(a => a.id === accountId) ?? primaryAccount

  useEffect(() => {
    if (account?.id && account.id !== accountId) {
      setAccountId(account.id)
    }
  }, [account?.id, accountId])

  useEffect(() => {
    const unsubscribeIdTokenChanged = onIdTokenChanged(getAuth(), handleIdTokenChanged)
    // const unsubscribeAuthStateChanged = onAuthStateChanged(getAuth(), handleAuthStateChanged)
    // const unsubscribe = firebaseApp.auth().onAuthStateChanged(onAuthStateChanged)
    return () => {
      unsubscribeIdTokenChanged()
      // unsubscribeAuthStateChanged()
    }
  }, [])

  const auth: Auth = {
    currentUser: state.currentUser,
    loading,
    user,
    isReady,
    setAccountId,
    account,
    accounts,
    primaryAccount,
    signOut,
    mutate,
    // claims: state.claims,
  }

  return (
    <AuthContext.Provider value={auth} children={children} />
  )
}

const useAuth = () => useContext(AuthContext)

export default useAuth