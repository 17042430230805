import type { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, Fragment } from 'react'
import { PortalProvider } from '../components/Portal'
import appMeta from '../configs/appMeta'
import { AlertProvider } from '../hooks/useAlert'
import { AuthProvider } from '../hooks/useAuth'
import type { Page } from '../types/page'
import { SWRConfig } from 'swr'
import { fetcher } from '../libs/swrHelpers'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'antd/dist/antd.css'
import '../styles/globals.css'
import '../libs/firebaseApp'
// import 'tailwindcss/tailwind.css'

type Props = AppProps & {
  Component: Page
}

const App: FC<Props> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? (page => page)
  const Layout = Component.layout ?? Fragment
  // firebaseApp
  return <>
    <Head>
      <title>{appMeta.name} {appMeta.separator} {appMeta.description}</title>

      <link rel="apple-touch-icon" sizes="57x57" href="/static/favicon/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/static/favicon/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/static/favicon/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/static/favicon/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/static/favicon/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/static/favicon/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/static/favicon/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/static/favicon/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/static/favicon/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/static/favicon/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/static/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/static/favicon/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href="https://rsms.me" />
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&family=Nunito+Sans:wght@200;300;400;700;900&display=swap" rel="stylesheet" />
    </Head>
    <SWRConfig value={{ fetcher }}>
      <AlertProvider>
        <PortalProvider>
          <AuthProvider>
            <Layout>
              {getLayout(<Component {...pageProps} />)}
            </Layout>
          </AuthProvider>
        </PortalProvider>
      </AlertProvider>
    </SWRConfig>
  </>
}

export default App