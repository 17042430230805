import { initializeApp } from 'firebase/app'
// import 'firebase/performance'
import 'firebase/auth'
import config from '../configs/firebase-app'

const firebaseApp = initializeApp(config)

// console.log('firebaseApp', firebaseApp?.getApp?.())

// if (!firebaseApp?.getApps?.()?.length) {
//   // Initialize Firebase
//   firebaseApp.initializeApp(config)

//   // Initialize Performance Monitoring and get a reference to the service
//   // firebaseApp.performance()
// }

export default firebaseApp
