export default function isset(accessor: Function) {
  try {
    return typeof accessor() !== 'undefined'
  } catch (_) {
    return false
  }
}

export function access(accessor: Function) {
  try {
    return accessor()
  } catch (_) {
    return undefined
  }
}