import Axios, { AxiosError } from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { getAuth } from 'firebase/auth'

import axiosConfig from '../configs/axios'
import { access } from './isset'

let counter = 0
const enableLogging = false

const axiosClient = Axios.create({
  baseURL: access(() => window.location.origin),
  ...axiosConfig,
})
axiosClient.interceptors.request.use(async config => {
  const now = config.headers.timestamp = String(Date.now())
  config.headers.counter = String(++counter)

  enableLogging && console.log([
    `#${counter}`,
    '..',
    config.method.toUpperCase(),
    new URL(config.url, config.baseURL).toString(),
    '|',
    now,
  ].join(' '))

  const token = await getAuth().currentUser?.getIdToken?.()
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`
  }
  const accountId = localStorage.getItem('accountId')
  if (accountId) {
    config.headers['account-id'] = accountId
  }
  config.adapter = throttleAdapterEnhancer(cacheAdapterEnhancer(axiosClient.defaults.adapter))

  return config
})
axiosClient.interceptors.response.use(async response => {
  const now = Date.now()

  enableLogging && console.log([
    `#${response.config.headers.counter}`,
    '->',
    response.config.method.toUpperCase(),
    new URL(response.config.url, response.config.baseURL).toString(),
    `(${response.status})`,
    `[${now - Number(response.config.headers.timestamp)}ms]`,
    '|',
    now,
  ].join(' '))

  if (response.headers['firebase-auth-force-refresh-token'] === 'true') {
    await getAuth().currentUser?.getIdToken?.(true)
  }

  return response
}, (error: AxiosError) => {
  const now = Date.now()

  enableLogging && console.log([
    error.config.method.toUpperCase(),
    new URL(error.config.url, error.config.baseURL).toString(),
    `(${error.response.status})`,
    `[${now - Number(error.config.headers.timestamp)}ms]`,
    '|',
    now,
  ].join(' '))

  console.error(error.name)
  console.error(error.message)
  console.error(error.code)
  console.error(error.stack)

  throw error
})

export default axiosClient
